<template>
  <v-row class="d-flex flex-column">
    <v-col>
      <v-row>
        <v-breadcrumbs :items="breadcrumbs" class="px-4">
          <template v-slot:item="{ item }">
            <v-breadcrumbs-item
              :to="item.to"
              :disabled="item.disabled"
            >
              {{ item.text.toUpperCase() }}
            </v-breadcrumbs-item>
          </template>
        </v-breadcrumbs>
      </v-row>

      <v-form
        ref="form"
        v-model="form.valid"
        lazy-validation>
        <v-row class="pt-1">
          <v-col cols="12">
            <v-alert
              v-model="ui.alert.enable"
              :dismissible="ui.alert.dismissible"
              :icon="ui.alert.icon"
              :type="ui.alert.type"
              class="mb-0 mt-3 mb-3"
            >
              {{ ui.alert.message }}
            </v-alert>
          </v-col>
          <v-col cols="12" sm="12" class="pt-0">
            <v-card outlined
                    :loading="ui.loading"
                    :disabled="ui.disabled"
                    class="rounded-lg overflow-hidden">
              <template slot="progress">
                <v-progress-linear
                  color="blue-grey darken-3"
                  height="6"
                  indeterminate
                ></v-progress-linear>
              </template>
              <v-row
                style="border-radius: 0"
                class="blue py-6 px-5 px-sm-9">
                <v-col cols="12" class="pb-0">
                  <v-toolbar-title class="title white--text pl-0">
                    {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.title') }}
                  </v-toolbar-title>
                </v-col>
              </v-row>
              <v-card-text class="px-0 pb-0 mb-8">
                <div class="px-5 px-sm-9 pt-6">
                  <v-row>
                    <v-col cols="12">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-tag-text-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.subtitle_main') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.education') }}
                      </p>
                      <v-row>
                        <div v-for="education in steps.education_level" :key="education.id">
                          <div class="d-flex flex-column radio-group-personalised mt-4" :class="[(form.data.education_level === education.id) ? 'group-selected' : '']" >
                            <v-checkbox :label="education.value" :value="education.id" v-model="form.data.education_level" :rules="rules_education_level">
                              <template v-slot:label class="flex flex-col">
                                <img :src="getImgUrl(education.name)"  style="height: 90px">
                                <p class="mb-1">{{ education.value }}</p>
                              </template>
                            </v-checkbox>
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.usage') }}
                      </p>
                      <v-row>
                        <div v-for="usage in steps.usage_type" :key="usage.id">
                          <div class="d-flex flex-column radio-group-personalised mt-4" :class="[(form.data.usage_type === usage.id) ? 'group-selected' : '']">
                            <v-checkbox
                              :label="usage.value"
                              :value="usage.id"
                              v-model="form.data.usage_type"
                              :rules="rules_usage_type"
                            >
                              <template v-slot:label class="flex flex-col">
                                <img :src="getImgUrl(usage.name)" class="mt-1" style="height: 60px">
                                <p class="mb-1 mt-1 text-center">{{ usage.value }}</p>
                              </template>
                            </v-checkbox>
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.storage') }}
                      </p>
                      <v-row>
                        <div v-for="cabinet in steps.storage_cabinet" :key="cabinet.id">
                          <div class="d-flex flex-column radio-group-personalised mt-4" :class="[(form.data.storage_cabinet === cabinet.id) ? 'group-selected' : '']">
                            <v-checkbox
                              :label="cabinet.value"
                              :value="cabinet.id"
                              v-model="form.data.storage_cabinet"
                              :rules="rules_storage_cabinet"
                            >
                              <template v-slot:label class="flex flex-col">
                                <img :src="getImgUrl(cabinet.name)" class="mt-1" style="height: 90px">
                                <p class="mb-1 mt-1 text-center">{{ cabinet.value }}</p>
                              </template>
                            </v-checkbox>
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.os_type') }}
                      </p>
                      <v-row>
                        <div v-for="os in steps.os" :key="os.id">
                          <div class="d-flex flex-column radio-group-personalised  mt-4" :class="[(form.data.os === os.id) ? 'group-selected' : '']">
                            <v-checkbox
                              :label="os.value"
                              :value="os.id"
                              v-model="form.data.os"
                              :rules="rules_os"
                            >
                              <template v-slot:label class="flex flex-col">
                                <img :src="getImgUrl(os.name)" class="mt-1" style="height: 50px">
                                <p class="mb-1 mt-1 text-center">{{ os.value }}</p>
                              </template>
                            </v-checkbox>
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.devices') }}
                      </p>
                      <v-autocomplete
                        v-model="form.data.products"
                        return-object
                        :item-text="item => item.reference +' - '+ item.label"
                        item-value="id"
                        :label="$vuetify.lang.t('$vuetify.product.products.add.ui.ui.form.extra.extra.label')"
                        clearable
                        dense
                        :items="steps.products"
                        multiple
                        deletable-chips
                        small-chips
                        hide-no-data
                        hide-selected
                        required
                        :rules="form.rules.devices"
                      >
                      </v-autocomplete>
                    </v-col>
                    <v-row align="stretch">
                      <v-col
                        v-for="item in form.data.products"
                        :key="item.id"
                        cols="12" sm="6" md="4" xl="4"
                      >
                        <ProductComponentVisualize  :product="item"></ProductComponentVisualize>
                      </v-col>
                    </v-row>
                  </v-row>
                  <!-- Refine configuration based on screen size and number of devices chosen -->
                  <v-row class="pt-8">
                    <v-col cols="12">
                      <div class="overline text-uppercase font-weight-bold pb-2 pt-2">
                        <v-icon class="pl-3 pr-3" color="purple">mdi-arrow-decision-outline</v-icon>
                        <span class="black--text text-center mx-auto">
                    {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.subtitle_secondary') }}
                      </span>
                      </div>
                      <v-divider></v-divider>
                    </v-col>
                    <!-- Screen size input -->
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.screen_size.title') }}
                      </p>
                      <v-row>
                        <v-col cols="12" sm="6" lg="3">
                          <v-text-field
                            v-model="form.data.screen_size_min"
                            :rules="screen_size_min_rule"
                            :label="$vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.screen_size.size_min.name')+'*'"
                            :hint="$vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.screen_size.size_min.hint')"
                            append-icon="mdi-image-size-select-small"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                          <v-text-field
                            v-model="form.data.screen_size_max"
                            :rules="screen_size_max_rule"
                            :label="$vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.screen_size.size_max.name')+'*'"
                            :hint="$vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.screen_size.size_max.hint')"
                            append-icon="mdi-image-size-select-small"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- Number of devices input -->
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.device_nb.title') }}
                      </p>
                      <v-row>
                        <v-col cols="12" sm="6" lg="3">
                          <v-text-field
                            v-model="form.data.device_nb_min"
                            :rules="device_nb_min_rule"
                            :label="$vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.device_nb.nb_min')+'*'"
                            append-icon="mdi-laptop"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="6" lg="3">
                          <v-text-field
                            v-model="form.data.device_nb_max"
                            :rules="device_nb_max_rule"
                            :label="$vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.device_nb.nb_max')+'*'"
                            append-icon="mdi-laptop"
                            required
                            dense
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-col>
                    <!-- Choose a suitable storage cabinet to fit enough devices of selected size -->
                    <v-col cols="12" sm="12">
                      <p class="body-2 font-weight-medium">
                        {{ $vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.fields.storage_precise') }}
                      </p>
                      <v-row>
                        <div v-for="cabinet in steps.storage_cabinet" :key="cabinet.id">
                          <div class="d-flex flex-column radio-group-personalised mt-4" :class="[(form.data.suitable_storage_cabinet === cabinet.id) ? 'group-selected' : '']">
                            <v-checkbox
                              :label="cabinet.value"
                              :value="cabinet.id"
                              v-model="form.data.suitable_storage_cabinet"
                              :rules="rules_suitable_storage_cabinet"
                            >
                              <template v-slot:label class="flex flex-col">
                                <img :src="getImgUrl(cabinet.name)" class="mt-1" style="height: 90px">
                                <p class="mb-1 mt-1 text-center">{{ cabinet.value }}</p>
                              </template>
                            </v-checkbox>
                          </div>
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-card-text>
              <v-card-actions class="d-xs-block px-3 px-sm-7">
                <v-btn
                  text
                  v-on:click="reset()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_cancel') }}
                </v-btn>
                <v-btn
                  color="primary"
                  text
                  v-on:click="submit()">
                  {{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
    <v-fab-transition>
      <div class="v-btn-right-bottom">
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="blue-grey lighten-1"
              fab
              small
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              :to="{name:'SiteConfigurators'}">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.back') }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="green lighten-1"
              small
              fab
              dark
              class="ml-2"
              v-bind="attrs"
              v-on="on"
              @click="submit">
              <v-icon>mdi-content-save-outline</v-icon>
            </v-btn>
          </template>
          <span>{{ $vuetify.lang.t('$vuetify.global.form.btn_save') }}</span>
        </v-tooltip>
      </div>
    </v-fab-transition>
  </v-row>
</template>
<script>

import _ from 'lodash'
import ProductComponentVisualize from '@/components/ProductComponentVisualize'

export default {
  components: {
    ProductComponentVisualize
  },
  data: () => ({
    staticUrl: '../../../assets/',
    ui: {
      loading: false,
      disabled: false,
      alert: {
        enable: false,
        dismissible: false,
        icon: 'mdi-check',
        type: 'info',
        message: 'Test'
      }
    },
    breadcrumbs: [],
    configuration: '',
    steps: '',
    site_initial: 'default',
    form: {
      valid: false,
      data: {
        education_level: null,
        usage_type: null,
        storage_cabinet: null,
        os: null,
        devices: [],
        screen_size_min: null,
        screen_size_max: null,
        device_nb_min: null,
        device_nb_max: null,
        suitable_storage_cabinet: null
      },
      rules: {}
    }
  }),
  computed: {
    rules_education_level () {
      return [
        !_.isNull(this.form.data.education_level)
      ]
    },
    rules_usage_type () {
      return [
        !_.isNull(this.form.data.usage_type)
      ]
    },
    rules_storage_cabinet () {
      return [
        !_.isNull(this.form.data.storage_cabinet)
      ]
    },
    rules_suitable_storage_cabinet () {
      return [
        !_.isNull(this.form.data.suitable_storage_cabinet)
      ]
    },
    rules_os () {
      return [
        !_.isNull(this.form.data.os)
      ]
    },
    screen_size_min_rule () {
      return [
        !_.isNull(this.form.data.screen_size_min) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.not_null'),
        !_.isNaN(this.form.data.screen_size_min) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.number'),
        (this.form.data.screen_size_min > 0) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.not_zero'),
        (!_.isNull(this.form.data.screen_size_max) ? (parseInt(this.form.data.screen_size_min, 10) > parseInt(this.form.data.screen_size_max, 10) ? this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.min_max') : true) : true)
      ]
    },
    screen_size_max_rule () {
      return [
        !_.isNull(this.form.data.screen_size_max) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.not_null'),
        !_.isNaN(this.form.data.screen_size_max) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.number'),
        (this.form.data.screen_size_max > 0) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.not_zero'),
        (!_.isNull(this.form.data.screen_size_min) ? (parseInt(this.form.data.screen_size_max, 10) < parseInt(this.form.data.screen_size_min, 10) ? this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.screen_size.rules.max_min') : true) : true)
      ]
    },
    device_nb_min_rule () {
      return [
        !_.isNull(this.form.data.device_nb_min) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.not_null'),
        !_.isNaN(this.form.data.device_nb_min) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.number'),
        (this.form.data.device_nb_min > 0) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.not_zero'),
        (!_.isNull(this.form.data.device_nb_max) ? (parseInt(this.form.data.device_nb_min, 10) > parseInt(this.form.data.device_nb_max, 10) ? this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.min_max') : true) : true)
      ]
    },
    device_nb_max_rule () {
      return [
        !_.isNull(this.form.data.device_nb_max) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.not_null'),
        !_.isNaN(this.form.data.device_nb_max) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.number'),
        (this.form.data.device_nb_max > 0) || this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.not_zero'),
        (!_.isNull(this.form.data.device_nb_min) ? (parseInt(this.form.data.device_nb_max, 10) < parseInt(this.form.data.device_nb_min, 10) ? this.$vuetify.lang.t('$vuetify.site.configurators.add.ui.form.fields.device_nb.rules.max_min') + this.form.data.device_nb_min + this.form.data.device_nb_max : true) : true)
      ]
    }
  },
  methods: {
    alert (type, icon, message, dismissible = true) {
      this.ui.alert.icon = icon
      this.ui.alert.type = type
      this.ui.alert.message = message
      this.ui.alert.dismissible = dismissible
      this.ui.alert.enable = true
    },
    reset () {
      this.goTop()
      this.$refs.form.reset()
      this.$refs.form.resetValidation()

      setTimeout(() => {
        this.form.data.education_level = this.form.default_data.education_level_id
        this.form.data.usage_type = this.form.default_data.usage_type_id
        this.form.data.storage_cabinet = this.form.default_data.storage_cabinet_id
        this.form.data.os = this.form.default_data.os_id
        this.form.data.screen_size_min = this.form.default_data.screen_size_min
        this.form.data.screen_size_max = this.form.default_data.screen_size_max
        this.form.data.device_nb_min = this.form.default_data.device_nb_min
        this.form.data.device_nb_max = this.form.default_data.device_nb_max
        this.form.data.suitable_storage_cabinet = this.form.default_data.suitable_storage_cabinet_id
        this.form.data.products = this.form.default_data.devices
      }, 10)
    },
    validate () {
      return this.$refs.form.validate()
    },
    /**
     * Goes to top
     * */
    goTop () {
      this.$vuetify.goTo(0)
    },
    /**
     * Gets image url for icon of configurator steps
     *
     * @param step
     * @return {*}
     */
    getImgUrl (step) {
      return require('../../../assets/' + step + '.png')
    },
    /**
     * Fetches steps for configurator
     * @return {Promise<void>}
     */
    async fetchSteps () {
      try {
        this.steps = (await this.axios.get('/site/configuratorSteps/' + this.$route.params.id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        this.breadcrumbs.find(e => e.id === 1).text = this.steps.site
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.site.configurators.add.notification.fail_fetch'))
      }
    },
    /**
     * Fetches current configuration
     * */
    async fetchConfiguration () {
      try {
        this.form.default_data = (await this.axios.get('/site/' + this.$route.params.id + '/configurator/one/' + this.$route.params.config_id, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })).data
        this.reset()
      } catch (e) {
        this.Sentry.captureException(e)
        this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.site.configurators.add.notification.fail_fetch'))
      }
    },
    /**
     * Cleans products array and plucks only the id
     */
    cleanProducts () {
      this.form.data.devices = []
      const self = this
      _.forEach(this.form.data.products, function (key, value) {
        self.form.data.devices.push(key.id)
      })
    },
    /**
     * Submits edit form : education level id, usage type id, storage cabinet id, os id, screen size min and max, nb of devices min and max and array of devices
     *
     * @return {Promise<boolean>}
     */
    async submit () {
      if (!this.validate()) {
        return false
      }

      try {
        this.ui.loading = true
        this.ui.disabled = true

        this.cleanProducts()

        await this.axios.post('/site/' + this.$route.params.id + '/configurator/edit/' + this.$route.params.config_id, {
          education_level_id: this.form.data.education_level,
          usage_type_id: this.form.data.usage_type,
          storage_cabinet_id: this.form.data.storage_cabinet,
          os_id: this.form.data.os,
          screen_size_min: this.form.data.screen_size_min,
          screen_size_max: this.form.data.screen_size_max,
          device_nb_min: this.form.data.device_nb_min,
          device_nb_max: this.form.data.device_nb_max,
          suitable_storage_cabinet_id: this.form.data.suitable_storage_cabinet,
          devices: this.form.data.devices
        }, {
          headers: {
            token: this.$session.get('auth_credentials').token
          }
        })

        await this.fetchConfiguration()

        this.reset()
        this.alert('success', 'mdi-checkbox-marked-circle', this.$vuetify.lang.t('$vuetify.site.configurators.edit.notification.edited'))
      } catch (e) {
        if (!this._.isUndefined(e.response?.status)) {
          switch (parseInt(e.response.status)) {
            // configuration with same parameters already exists
            case 302:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.site.configurators.edit.notification.exist'))
              break
            // invalid parameters
            case 400:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.site.configurators.edit.notification.fail'))
              break
            // site not found, redirect to home
            case 404:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.site.configurators.edit.notification.site_not_exist')
                  }
                }
              })
              break
            // configuration not found, redirect to home
            case 410:
              await this.$router.push({
                name: 'Home',
                params: {
                  alert: {
                    enable: true,
                    dismissible: true,
                    icon: 'mdi-alert',
                    type: 'warning',
                    message: this.$vuetify.lang.t('$vuetify.site.configurators.edit.notification.not_exist')
                  }
                }
              })
              break
            // certain products not found
            case 405:
              this.alert('warning', 'mdi-alert', this.$vuetify.lang.t('$vuetify.site.configurators.edit.notification.product_not_exist'))
              break
            default:
              this.alert('error', 'mdi-alert-octagon', this.$vuetify.lang.t('$vuetify.site.configurators.edit.notification.unknown_save'))
          }
          this.Sentry.captureException(e)
        }
      } finally {
        this.goTop()
        this.ui.loading = false
        this.ui.disabled = false
      }
    }
  },
  watch: {},
  created () {
    this.breadcrumbs = [
      {
        text: this.$vuetify.lang.t('$vuetify.home.name'),
        disabled: false,
        to: { name: 'Home' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.site.name'),
        disabled: true
      },
      {
        id: 1,
        text: this.site_initial,
        disabled: true
      },
      {
        text: this.$vuetify.lang.t('$vuetify.site.configurators.name'),
        disabled: false,
        to: { name: 'SiteConfiguratorsList' }
      },
      {
        text: this.$vuetify.lang.t('$vuetify.site.configurators.edit.title'),
        disabled: true
      }
    ]
    this.form.rules = {
      devices: [
        value => value.length > 0 || this.$vuetify.lang.t('$vuetify.site.configurators.edit.ui.form.rules.product')
      ]
    }
  },
  mounted () {
    this.fetchSteps()
    this.fetchConfiguration()
  }
}
</script>
<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

::v-deep {
  .v-input--selection-controls__input {
    display: none;
  }

  .group-selected {
    background: #4bc8bd !important;
    color: #fff;
    border-color: #2196F3;
  }

  .radio-group-personalised {
    height: 80%!important;
    justify-content: center;
    width: 200px;
    margin-right: 10px;
    background: #fff;
    border: 1px solid #ddd;
    padding: .5rem 1.25rem;
    border-radius: 5px;
    cursor: pointer;
    color: #444;
    transition: box-shadow 400ms ease;

    &:hover {
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12);
    }

    .v-label {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
